import React from 'react'
import { HashRouter as Router, Routes, Route } from 'react-router-dom'
import HomeScreen from './screens/HomeScreen'
import Header from './components/Header'
import Footer from './components/Footer'
import DashboardScreen from './screens/DashboardScreen'
import RegisterScreen from './screens/RegisterScreen'
import LoginScreen from './screens/LoginScreen'
import AboutUsScreen from './screens/AboutUsScreen'
import ContactUsScreen from './screens/ContactUsScreen'
import PrivateRoutes from './utilities/PrivateRoutes'
import UserActivationLinkScreen from './screens/UserActivationLinkScreen'
import ActivateScreen from './screens/ActivateScreen'
import ForgotPasswordResetScreen from './screens/ForgotPasswordResetScreen'
import ForgotPasswordScreen from './screens/ForgotPasswordScreen'
import UserInformationScreen from './screens/UserInformationScreen'

const App = () => {
  return (
    <Router>
      <Header/>
      <Routes> 
        <Route path='/' element={<HomeScreen/>} />
        <Route path='/user/:userId' element={<UserInformationScreen/>} />

        {/* <Route path='/login' element={<LoginScreen/>} />
        <Route path='/about' element={<AboutUsScreen/>} />
        <Route path='/contact' element={<ContactUsScreen/>} />
        <Route path='/password/reset/confirm/:uid/:token' element={<ForgotPasswordResetScreen/>} />
        <Route path='/activate/:uid/:token' element={<ActivateScreen/>}/>
        <Route path='/forgot/password' element={<ForgotPasswordScreen/>} />
        <Route path='/create/activation/link' element={<UserActivationLinkScreen/>} />
        <Route path='/register' element={<RegisterScreen/>} /> */}
        {/* <Route path='/shop' element={<ProductsScreen/>} />
        <Route path='/shop/:category' element={<ProductsScreen/>} /> 
        <Route path='/shop/:category/:pName' element={<ProductScreen/>} />
        <Route path='/cart' element={<CartScreen/>} />
        <Route path='/aboutus' element={<AboutUsScreen/>} /> */}
        {/* <Route path='/'  element={<PrivateRoutes/>} > */}
          {/* <Route path='/cart/checkout' element={<CheckOutScreen/>}/> */}
          {/* <Route path='/dashboard' element={<DashboardScreen/>} /> */}
          {/* <Route path='/profile/order/:order' element={<ProfileOrderDetailsScreen/>} /> */}
        {/* </Route> */}
      </Routes>
      <Footer/>
    </Router>
  )
}

export default App